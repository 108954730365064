/***
 * Wraps an asynchronous function to ensure only one call at a time is in
 * flight. Any extra calls are dropped, except the last one, which waits for
 * the previous call to complete.
 */
export function throttlePromiseWithResult(wrapped) {
    let current;
    let pending;
    return function (...args) {
        const self = this;
        const runCurrent = () => {
            current = wrapped.apply(self, args).finally(() => {
                current = undefined;
                if (pending) {
                    pending.run();
                    pending = undefined;
                }
            });
            return current;
        };
        if (!current)
            return runCurrent();
        pending?.reject();
        const next = new Promise((resolve, reject) => {
            pending = {
                run: () => runCurrent().then(res => {
                    resolve(res);
                    return res;
                }, err => {
                    reject(err);
                    throw err;
                }),
                reject: () => reject(new Error('Throttled')),
            };
        });
        return next;
    };
}
/* doesn't fail the promise if it's throttled */
export function throttlePromise(wrapped) {
    const throttler = throttlePromiseWithResult(wrapped);
    return function (...args) {
        return throttler.apply(this, args).catch(() => { });
    };
}
/**
 * Wraps an asynchronous function to return a promise that resolves
 * after completion plus a delay (regardless if the wrapped function resolves
 * or rejects).
 */
export function finallyDelay(delay, wrapped) {
    return function (...args) {
        const self = this;
        return new Promise(resolve => {
            wrapped.apply(self, args).finally(() => setTimeout(resolve, delay.apply(self, args)));
        });
    };
}
/**
 * Wraps an asynchronous function to ensure only one call at a time is in flight. Any extra calls
 * are dropped, except the last one, which waits for the previous call to complete plus a delay.
 */
export function throttlePromiseDelay(delay, wrapped) {
    return throttlePromise(finallyDelay(delay, wrapped));
}
/**
 * Ensures calls to the wrapped function are spaced by the given delay.
 * Any extra calls are dropped, except the last one, which waits for the delay.
 */
export function throttle(delay, wrapped) {
    return throttlePromise(function (...args) {
        wrapped.apply(this, args);
        return new Promise(resolve => setTimeout(resolve, delay));
    });
}
export function idleTimer(delay, onIdle, onWakeUp) {
    const events = ['mousemove', 'touchstart'];
    let listening = false, active = true, lastSeenActive = performance.now();
    const onActivity = () => {
        if (!active) {
            // console.log('Wake up');
            onWakeUp();
        }
        active = true;
        lastSeenActive = performance.now();
        stopListening();
    };
    const startListening = () => {
        if (!listening) {
            events.forEach(e => document.addEventListener(e, onActivity));
            listening = true;
        }
    };
    const stopListening = () => {
        if (listening) {
            events.forEach(e => document.removeEventListener(e, onActivity));
            listening = false;
        }
    };
    setInterval(() => {
        if (active && performance.now() - lastSeenActive > delay) {
            // console.log('Idle mode');
            onIdle();
            active = false;
        }
        startListening();
    }, 10000);
}
export function debounce(f, wait, immediate = false) {
    let timeout;
    let lastBounce = 0;
    return function (...args) {
        const self = this;
        if (timeout)
            clearTimeout(timeout);
        timeout = undefined;
        const elapsed = performance.now() - lastBounce;
        lastBounce = performance.now();
        if (immediate && elapsed > wait)
            f.apply(self, args);
        else
            timeout = setTimeout(() => {
                timeout = undefined;
                f.apply(self, args);
            }, wait);
    };
}
